@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap'); */

body {
  margin: 0;
  background: #3b3963;
  /* background: #000; */
  font-family: "Kanit", sans-serif;
  /* color: #fff; */
}
.select-2 > div {
  color: #000;
}
label,
h1,
h2,
h3,
h4,
h5 {
  color: #fff;
}
.color-white {
  color: #fff;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.nav-link:focus,
.nav-link:hover {
  color: #ffffff;
}
a.nav-link.active {
  background: #44416f !important;
  color: #fff;
}
a {
  text-decoration: none;
}

@media print {
  .navbar {
    display: none;
  }
  .btnPrint {
    display: none;
  }
}
/* css ส่วนสำหรับการแบ่งหน้าข้อมูลสำหรับการพิมพ์ */
@media all {
  .page-break {
    display: none;
  }
  .page-break-no {
    display: none;
  }
}
@media print {
  .page-break {
    display: block;
    height: 1px;
    page-break-before: always;
  }
  .page-break-no {
    display: block;
    height: 1px;
    page-break-after: avoid;
  }
  .table > thead > tr > th,
  .table > tbody > tr > td {
    color: #000 !important;
  }
  .print-header {
    color: #000 !important;
  }
  tfoot > tr > td {
    color: #000 !important;
    font-weight: bold;
    /* font-size: 38px; */
  }
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
  /* padding: 5px; */
  /* font-size: 14px; */
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #fff;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  font-size: 18px;
}
.card {
  /* border: 1px solid rgb(0 0 0); */
  background-color: transparent;
  border: none;
}
.btn-success {
  color: #fff;
  background-color: #00b960;
  border-color: #008d4b;
}
a {
  color: #212529;
  text-decoration: none;
}
.navbar-light .navbar-nav .nav-link {
  color: rgb(255 255 255);
}
.navbar-light .navbar-toggler {
  background: #fff;
}
@media only screen and (max-width: 768px) {
  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    position: absolute;
    right: 9px;
    background: #1f2023;
    top: 50px;
    padding: 10px;
    z-index: 900;
    box-shadow: 2px 4px 8px #000000;
  }
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #1f2023;
}
.table > thead > tr > th,
.table > tbody > tr > td {
  color: #fff;
  white-space: nowrap;
}
tfoot > tr > td {
  color: #fff;
  font-weight: bold;
  /* font-size: 38px; */
}
.table-config > tbody > tr > td {
  font-size: 18px;
  padding: 5px;
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgb(255 255 255);
}
.card-number a {
  color: #ffc800;
}
.dropdown-menu {
  background: #000 !important;
}
.dropdown-item {
  color: #fff;
  font-size: 18px;
  margin-bottom: 5px;
}
.form-control {
  border-radius: 0px;
  font-weight: bold;
}
.btn {
  border-radius: 0px;
  font-weight: bold;
}
.col-date {
  justify-content: center;
  display: flex;
  align-items: center;
  color: #fff;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  border-radius: 0px;
}
i.fa.fa-angle-double-left {
  color: #ffeb3b;
}
.card-active {
  background: #ffb410;
  color: #000;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #000;
  background-color: #efb709;
  font-weight: bold;
}
#react-select-2-listbox {
  color: #000;
}
.data-list {
  max-height: 140px;
  overflow: hidden;
  overflow-y: scroll;
}
.navbar-light .navbar-brand {
  color: rgb(255 255 255 / 90%);
}
.bg-light {
  background-color: #2c2a49 !important;
  color: #fff;
}
.card-body {
  flex: 1 1 auto;
  padding: 0.6rem 0.5rem;
  /* box-shadow: 1px 1px 5px #4949542e; */
  background: #353157;
  border-radius: 10px;
  border: 1px solid rgba(233, 229, 246, 0.08);
}
.modal-content {
  background-color: #3b3963;
}
.card-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  height: 95%;
  margin-top: 5px;
  background: #2c2a49;
  border-radius: 10px;
  border-bottom: 1px solid #7976a0;
}
.btn {
  border-radius: 5px;
}
.bg-menu {
  background: #49a8bb;
  background: #44416f;
  box-shadow: inset 0 0 0 3px #9575cd;
  color: rgba(233, 229, 246, 0.87);
}
.tx-header {
  background: #2d2a49;
  border-bottom: 1px solid #1f1d33;
  /* height: 56px; */
  /* min-height: 56px; */
  padding: 5px;
}
.alert-info {
  color: #ffffff !important;
  background-color: #353156 !important;
  border-color: #f6f9fa !important;
}
