.navbar-light .navbar-toggler {
  background: #efb709;
}
.navbar-setting {
  flex-wrap: nowrap;
  overflow: hidden;
  overflow-x: scroll;
  /* background: blue; */
  white-space: nowrap;
}
.navbar-setting::-webkit-scrollbar {
  height: 5px;
}

/* Track */
.navbar-setting::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.navbar-setting::-webkit-scrollbar-thumb {
  background: #1f2023;
}

/* Handle on hover */
.navbar-setting::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.text-white {
  color: #fff;
}
.text-right {
  text-align: right;
}
.text-yellow {
  color: #efb709;
}
.btn-info {
  color: #000;
  background-color: #efb709;
  border-color: #efb709;
}
.btn-link,
.btn-link:active,
.btn-link:hover {
  color: #efb709;
}
.table > thead > tr > th,
.table > tbody > tr > td {
  padding-left: 10px;
  padding-right: 10px;
}
.btn-success {
  color: #fff;
  background-color: #009688;
  border-color: #009688;
}
.table > thead > tr > th {
  padding: 10px;
  /* background: #020202; */
}

.btn-primary {
  background: #7459c8;
  border-color: #3f51b5;
  background: #7459c8;
  background-position: 50%;
  border-color: #9575cd;
  color: #fff;
  transition: background 0.8s;
}
.btn-primary:hover {
  background: #744ee8;
  border-color: #ffffff;
}
.flex-container {
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10%;
}
.form-control {
  /* font-size: 18px !important; */
}
.form-date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  height: 100vh;
  /* ?background: rgb(73, 168, 187); */
  /* padding: 20px; */
}
.th-total {
  color: #efb709 !important;
}
